import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthModule} from '@auth0/auth0-angular';
import {environment} from '../environments/environment';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {IdTokenAuthHttpInterceptor} from '../interceptor/IdTokenAuthHttpInterceptor';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { TradesComponent } from './trades/trades.component';
import { ExecutionsComponent } from './executions/executions.component';
import { TradeComponent } from './trade/trade.component';
import { CreateTradeComponent } from './create-trade/create-trade.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { EditTradeComponent } from './edit-trade/edit-trade.component';
import { CreateExecutionComponent } from './create-execution/create-execution.component';
import { EditExecutionComponent } from './edit-execution/edit-execution.component';
import { CandlestickChartComponent } from './candlestick-chart/candlestick-chart.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AttachmentsComponent } from './attachments/attachments.component';
import { StockInfoComponent } from './stock-info/stock-info.component';
import { ListComponent } from './list/list.component';
import { OnceUponAPipeModule } from 'once-upon-a-pipe';
import { FundamentalsTableComponent } from './fundamentals-table/fundamentals-table.component';
import { StockchartsComponent } from './stockcharts/stockcharts.component';
import {SafePipe} from './list/safe.pipe';
import { RelatedStocksComponent } from './related-stocks/related-stocks.component';
@NgModule({
  declarations: [
    AppComponent,
    DashBoardComponent,
    TradesComponent,
    ExecutionsComponent,
    TradeComponent,
    CreateTradeComponent,
    EditTradeComponent,
    CreateExecutionComponent,
    EditExecutionComponent,
    CandlestickChartComponent,
    AttachmentsComponent,
    StockInfoComponent,
    ListComponent,
    FundamentalsTableComponent,
    StockchartsComponent,
    SafePipe,
    RelatedStocksComponent

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AuthModule.forRoot({
      ...environment.auth,
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.apiUrl}*`,
          }
        ]
      }
    }),
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    OnceUponAPipeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: IdTokenAuthHttpInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
