<div class="container align-content-center" *ngIf="isLoading">
  <div class="spinner-border text-light" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="container" *ngIf="!isLoading">
  <div class="row">
    <div class="col-sm-12">
      <h2>{{trade.symbol}} </h2>
      <span class="mr-1 badge text-white" [class.isOpen]="trade.status === 'open'" [class.isClosed]="trade.status === 'closed'">{{trade.status}}</span>
      <span *ngIf="trade.isLong" class="mr-1 badge text-white app-green-bg">
        long
      </span>
      <span *ngIf="trade.isShort" class="mr-1 badge text-white app-red-bg">
        short
      </span>
      <div class="float-right">
        <button [routerLink]="['/trade', id, 'edit']" type="button" class="btn btn-primary">Edit trade</button>
      </div>
    </div>
    <div class="col-sm-12">

    </div>
    <div class="col-sm-12 mb-2 mt-2">
      <div class="row">
        <div class="col-sm-6 dark-light-text" >
          Realized P&L:
        </div>
        <div class="col-sm-6" [class.app-green-text]="trade.profitable" [class.app-red-text]="!trade.profitable">
          {{trade.profitAndLosses | number: '1.2-4'}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 dark-light-text" >
          Open Position:
        </div>
        <div class="col-sm-6">
          {{trade.openPosition}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 dark-light-text" >
          Total shares traded:
        </div>
        <div class="col-sm-6">
          {{trade.totalSharesTraded}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 dark-light-text" >
          Time range:
        </div>
        <div class="col-sm-6">
          {{trade.dateStart | date: 'shortDate'}} - {{trade.dateEnd | date: 'shortDate'}}
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <button type="button" class="btn btn-primary mb-2" (click)="generateChart()" [disabled]="chartLoading">
        Generate chart
      </button>
      <div class="spinner-border text-light" role="status" *ngIf="chartLoading">
      <span class="sr-only">Loading...</span>
    </div>
    </div>
    <div class="col-sm-12" *ngIf="trade.chartData">
      <app-candlestick-chart [priceData]="trade.chartData" [executions]="executions"></app-candlestick-chart>
    </div>
    <div class="col-sm-12" *ngIf="trade.notes">
      <h4>Notes</h4>
      {{trade.notes}}
    </div>
    <div class="col-sm-12">
      <h4>Executions</h4>
      <button type="button" class="btn btn-primary mb-2" [routerLink]="['/trade', id, 'execution', 'create', trade.symbol]">Add new execution</button>
    <table class="table app-table">
      <thead class="primary-dark-color-bg-light-1">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Side</th>
        <th scope="col">Qty</th>
        <th scope="col">Price</th>
        <th scope="col">Date</th>
        <th scope="col">Realized P&L</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let execution of executions; index as i">
        <th scope="row"><a [routerLink]="['/execution',execution?.SK,'edit']">{{execution?.SK}}</a></th>
        <td [className]="execution?.side">{{execution?.side}}</td>
        <td><span *ngIf="execution?.side === 'S'">-</span>{{execution?.amount}}</td>
        <td>{{execution?.price | number: '1.2-4' | currency: execution?.currency}}</td>
        <td>{{execution?.GSI3SK | date: 'long'}}</td>
        <td [class.app-green-text]="execution?.partialIsProfitable" [class.app-red-text]="!execution?.partialIsProfitable">
          {{execution?.partialProfit | number: '1.2-4' | currency: execution?.currency}}
      </tr>
      </tbody>
    </table>
    </div>
  </div>
</div>

<app-attachments></app-attachments>
