<header>
  <nav class="navbar navbar-expand-sm navbar-dark secondary-dark-color-bg" aria-label="Third navbar example">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="['/']">TJ</a>


      <div class=" " id="navbarsExample03">
        <ul class="navbar-nav me-auto mb-2 mb-sm-0">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/trades']" *ngIf="auth.isAuthenticated$ | async">Trades</a>
          </li>
          <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
            <a class="nav-link" [routerLink]="['/executions']">Executions</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/stock-info']" *ngIf="auth.isAuthenticated$ | async">Stock info</a>
          </li>
          <li class="nav-item right">
            <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
              <a class="nav-link" (click)="auth.logout({ returnTo: document.location.origin })">Log out</a>
            </ng-container>
            <ng-template #loggedOut>
              <a class="nav-link" (click)="auth.loginWithRedirect()">Log in</a>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

<main>
  <router-outlet></router-outlet>
</main>
