import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-stockcharts',
  templateUrl: './stockcharts.component.html',
  styleUrls: ['./stockcharts.component.scss']
})
export class StockchartsComponent implements OnInit {
  @Input() symbol = '';

  constructor() { }

  ngOnInit(): void {
  }

}
