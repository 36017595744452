import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {TradesService} from '../services/trades.service';
import {TradeModel} from '../models/trade.model';
import {DOCUMENT} from '@angular/common';
import {WsService} from '../services/ws.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  trades: any[] = [];

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService,
    private wsService: WsService) {
  }

  ngOnInit(): any {
    // this.wsService.connect();
    // this.wsService.sendMessage('test');
  }
}
