<table class="table app-table">
  <tbody>
  <tr>
    <td class="primary-dark-color-bg-light-1">Float</td>
    <td> <span ngbPopover="" popoverTitle="Finviz" triggers="mouseenter:mouseleave"> {{fundamentals.finviz?.value?.shares_float }}</span></td>
    <td class="primary-dark-color-bg-light-1">Market Cap</td>
    <td> <span ngbPopover="" popoverTitle="Finviz" triggers="mouseenter:mouseleave"> {{fundamentals.finviz?.value?.market_cap }}</span></td>
  </tr>
  <tr>
    <td class="primary-dark-color-bg-light-1">Outs. Shares</td>
    <td> <span ngbPopover="" popoverTitle="Finviz" triggers="mouseenter:mouseleave">{{fundamentals.finviz?.value?.shares_outstand }} </span> | <span ngbPopover="" popoverTitle="Yahoo" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.defaultKeyStatistics?.sharesOutstanding?.fmt}}</span></td>
    <td class="primary-dark-color-bg-light-1">Short Float</td>
    <td> <span ngbPopover="" popoverTitle="Yahoo" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.defaultKeyStatistics?.shortPercentOfFloat?.fmt }} </span></td>
  </tr>

  <tr>
    <td class="primary-dark-color-bg-light-1">Shares short</td>
    <td><span ngbPopover="" popoverTitle="Yahoo" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.defaultKeyStatistics?.sharesShort?.fmt }}</span></td>
    <td class="primary-dark-color-bg-light-1">Shares short prev month</td>
    <td><span ngbPopover="" popoverTitle="Yahoo" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.defaultKeyStatistics?.sharesShortPriorMonth?.fmt }}</span></td>
  </tr>
  <tr>
    <td class="primary-dark-color-bg-light-1">Avg Vol</td>
    <td><span ngbPopover="" popoverTitle="Finviz" triggers="mouseenter:mouseleave"> {{fundamentals.finviz?.value?.avg_vol }}</span></td>
    <td class="primary-dark-color-bg-light-1">RVol</td>
    <td><span ngbPopover="" popoverTitle="Finviz" triggers="mouseenter:mouseleave"> {{fundamentals.finviz?.value?.rel_vol }}</span></td>
  </tr>

  <tr>
    <td class="primary-dark-color-bg-light-1">P/E</td>
    <td><span ngbPopover="To determine the P/E value, one must simply divide the current stock price by the earnings per share (EPS)." popoverTitle="Finviz" triggers="mouseenter:mouseleave"> {{fundamentals.finviz?.value?.p_e }}</span></td>
    <td class="primary-dark-color-bg-light-1">P/S</td>
    <td><span ngbPopover="To determine the P/S ratio, one must divide the current stock price by the sales per share." popoverTitle="Finviz" triggers="mouseenter:mouseleave">{{fundamentals.finviz?.value?.p_s }}</span></td>
  </tr>
  <tr>
    <td class="primary-dark-color-bg-light-1">EPS</td>
    <td><span ngbPopover="" popoverTitle="Yahoo (TTM)" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.defaultKeyStatistics?.trailingEps?.fmt }} </span></td>
    <td class="primary-dark-color-bg-light-1">EPS growth YoY</td>
    <td><span ngbPopover="" popoverTitle="Finviz" triggers="mouseenter:mouseleave"> {{fundamentals.finviz?.value?.eps_growth_yoy }} </span></td>
  </tr>
  <tr>
    <td class="primary-dark-color-bg-light-1">Income</td>
    <td><span ngbPopover="Income from continuing operations is a net income category found on the income statement that accounts for a company’s regular business activities. Income from continuing operations is also known as operating income. Continuing operations are the primary source of income for most successful businesses. If a company makes most of its money from non-core activities, some analysts may raise red flags. For instance, a car company may be headed for trouble if it is making far more money from its financing and credit operations than from selling automobiles." popoverTitle="Finviz TTM" triggers="mouseenter:mouseleave"> {{fundamentals.finviz?.value?.income_ttm }} </span>| <span ngbPopover="Income from continuing operations is a net income category found on the income statement that accounts for a company’s regular business activities. Income from continuing operations is also known as operating income. Continuing operations are the primary source of income for most successful businesses. If a company makes most of its money from non-core activities, some analysts may raise red flags. For instance, a car company may be headed for trouble if it is making far more money from its financing and credit operations than from selling automobiles." popoverTitle="Yahoo ({{lastIncomeStatement?.endDate?.fmt}})" triggers="mouseenter:mouseleave"> {{lastIncomeStatement?.netIncome?.fmt}} </span></td>
    <td class="primary-dark-color-bg-light-1">Revenue</td>
    <td> <span ngbPopover="Revenue is the money generated from normal business operations, calculated as the average sales price times the number of units sold. It is the top line (or gross income) figure from which costs are subtracted to determine net income. Revenue is also known as sales on the income statement. " popoverTitle="Finviz (TTM)" triggers="mouseenter:mouseleave"> {{fundamentals.finviz?.value?.revenue_ttm }} </span> | <span ngbPopover="" popoverTitle="Yahoo ({{lastIncomeStatement?.endDate?.fmt}})" triggers="mouseenter:mouseleave"> {{lastIncomeStatement?.totalRevenue?.fmt}}</span></td>
  </tr>
  <tr>
    <td class="primary-dark-color-bg-light-1">Total Cash</td>
    <td><span ngbPopover="" popoverTitle="Yahoo" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.financialData?.totalCash?.fmt }}</span></td>
    <td class="primary-dark-color-bg-light-1">Total Debt</td>
    <td><span ngbPopover="" popoverTitle="Yahoo" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.financialData?.totalDebt?.fmt }}</span></td>
  </tr>
  <tr>
    <td class="primary-dark-color-bg-light-1">Total Expenses </td>
    <td><span ngbPopover="Operating Expenses (cost of goods sold, administrative fees, office supplies, direct labor, and rent) + Non-operating Expenses (interest charges and other costs associated with borrowing money)" popoverTitle="Yahoo ({{lastIncomeStatement?.endDate?.fmt}})" triggers="mouseenter:mouseleave"> {{lastIncomeStatement?.totalOperatingExpenses?.fmt}}</span></td>
    <td class="primary-dark-color-bg-light-1">Total Operating Income as reported </td>
    <td><span ngbPopover="Operating income is the sum total of a company's profit after subtracting its regular, recurring costs and expenses.  operating income is a company's profit after subtracting operating expenses, which are the costs of running the daily business. Operating income helps investors separate out the earnings for the company's operating performance by excluding interest and taxes. Revenue is the total amount of income generated by a company for the sale of its goods or services before any expenses are deducted" popoverTitle="Yahoo ({{lastIncomeStatement?.endDate?.fmt}})" triggers="mouseenter:mouseleave"> {{lastIncomeStatement?.operatingIncome?.fmt}}</span></td>
  </tr>
  <tr>
    <td class="primary-dark-color-bg-light-1">EPS estimate</td>
    <td><span ngbPopover="EPS estimate for next year" popoverTitle="Finviz" triggers="mouseenter:mouseleave"> {{fundamentals.finviz?.value?.eps_next_y}}</span> | <span ngbPopover="EPS estimate for next quater" popoverTitle="Finviz" triggers="mouseenter:mouseleave"> {{fundamentals.finviz?.value?.eps_next_q}}</span></td>
    <td class="primary-dark-color-bg-light-1">Operating cash flow</td>
    <td><span ngbPopover="Operating cash flows concentrate on cash inflows and outflows related to a company's main business activities, such as selling and purchasing inventory, providing services, and paying salaries. Any investing and financing transactions are excluded from the operating cash flows section and reported separately, such as borrowing, buying capital equipment, and making dividend payments." popoverTitle="Yahoo (TTM)" triggers="mouseenter:mouseleave"> {{fundamentals?.yahoo?.value?.financialData?.operatingCashflow?.fmt}}</span></td>
  </tr>
  <tr>
    <td class="primary-dark-color-bg-light-1">Operating Margin</td>
    <td><span ngbPopover="The operating margin measures how much profit a company makes on a dollar of sales after paying for variable costs of production, such as wages and raw materials, but before paying interest or tax. It is calculated by dividing a company’s operating income by its net sales. Higher ratios are generally better, illustrating the company is efficient in its operations and is good at turning sales into profits.  " popoverTitle="Yahoo (TTM)" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.financialData?.operatingMargins?.fmt }}</span></td>
    <td class="primary-dark-color-bg-light-1">Profit Margin</td>
    <td><span ngbPopover="It represents what percentage of sales has turned into profits. Simply put, the percentage figure indicates how many cents of profit the business has generated for each dollar of sale. " popoverTitle="Yahoo" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.financialData?.profitMargins?.fmt }}</span></td>
  </tr>
  <tr>
    <td class="primary-dark-color-bg-light-1">Shares bought insiders</td>
    <td><span ngbPopover="" popoverTitle="Yahoo" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.netSharePurchaseActivity?.buyInfoShares?.fmt }}</span></td>
    <td class="primary-dark-color-bg-light-1">Shares sold insiders</td>
    <td><span ngbPopover="" popoverTitle="Yahoo" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.netSharePurchaseActivity?.sellInfoShares?.fmt }}</span></td>
  </tr>
  <tr>
    <td class="primary-dark-color-bg-light-1">ATR</td>
    <td><span ngbPopover="" popoverTitle="Finviz" triggers="mouseenter:mouseleave"> {{fundamentals.finviz?.value?.atr }}</span></td>
    <td class="primary-dark-color-bg-light-1"># Institutions</td>
    <td><span ngbPopover="" popoverTitle="Yahoo" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.majorHoldersBreakdown?.institutionsCount?.longFmt}}</span></td>
  </tr>
  <tr>
  <td class="primary-dark-color-bg-light-1">Insider %</td>
    <td><span ngbPopover="" popoverTitle="Yahoo" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.majorHoldersBreakdown?.insidersPercentHeld?.fmt }}</span></td>
  <td class="primary-dark-color-bg-light-1">Institutions %</td>
    <td><span ngbPopover="" popoverTitle="Yahoo" triggers="mouseenter:mouseleave"> {{fundamentals.yahoo?.value?.majorHoldersBreakdown?.institutionsPercentHeld?.fmt }}</span></td>
  </tr>

  <tr>
    <td colspan="2"><a href="https://it.finance.yahoo.com/quote/{{symbol}}/financials?p={{symbol}}" target="_blank">Revenue</a></td>
    <td colspan="2"><a href="https://www.gurufocus.com/stock/{{symbol}}/insider" target="_blank">Insiders</a></td>
  </tr>
  <tr>
    <td colspan="2"><a href="https://www.tipranks.com/stocks/{{symbol}}/forecast" target="_blank">Tipranks</a></td>
    <td colspan="2"><a href="http://shortvolumes.com/?t={{symbol}}" target="_blank">Short Vol</a></td>
  </tr>

  </tbody>
</table>
