import {Component, Input, OnInit} from '@angular/core';
import {FinvizFundamental, YahooFundamental} from '../../models/stock-info.model';
import {faInfo} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-fundamentals-table',
  templateUrl: './fundamentals-table.component.html',
  styleUrls: ['./fundamentals-table.component.scss']
})
export class FundamentalsTableComponent implements OnInit {
  @Input() fundamentals: {
    finviz: FinvizFundamental | null;
    yahoo: YahooFundamental | null;
  };
  @Input() otherFundamentals = {
    nextEarnings: '',
    operatingExpenses: '',
    operatingExpensesDate: ''
  };
  @Input() symbol = '';
  faInfo = faInfo;
  lastIncomeStatement: any;

  constructor() {
    this.fundamentals = {
      finviz: null,
      yahoo: null
    };
  }

  ngOnInit(): void {
    // tslint:disable-next-line:max-line-length
    if (this.fundamentals.yahoo?.value.incomeStatementHistory && this.fundamentals.yahoo?.value.incomeStatementHistory.incomeStatementHistory && this.fundamentals.yahoo?.value.incomeStatementHistory.incomeStatementHistory.length > 0) {
      this.lastIncomeStatement = this.fundamentals.yahoo?.value.incomeStatementHistory.incomeStatementHistory[0];
    }
  }
}
