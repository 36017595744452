import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TradesService} from '../../services/trades.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-create-trade',
  templateUrl: './create-trade.component.html',
  styleUrls: ['./create-trade.component.scss']
})
export class CreateTradeComponent implements OnInit {
  form = this.fb.group({
    symbol: ['', Validators.required],
    notes: [null]
  });
  errorMessage = null;
  constructor(private fb: FormBuilder, private tradeService: TradesService, private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    console.log(this.form.value);
    this.tradeService.createTrade(this.form.value).subscribe( data => {
      console.log(data);
      this.tradeService.clearCache();
      this.router.navigate(['/trade', data.SK.replace('TRADE#', '')]);
    }, error => {
      console.error(error.error.message);
      this.errorMessage = error.error.message;
      });
  }
}
