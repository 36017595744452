import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {StockInfoService} from '../../services/stock-info.service';
import {StockInfo, FinvizFundamental, YahooFundamental, StockTwit} from '../../models/stock-info.model';
import {faRetweet} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-stock-info',
  templateUrl: './stock-info.component.html',
  styleUrls: ['./stock-info.component.scss']
})
export class StockInfoComponent implements OnInit {
  faRefresh = faRetweet;

  symbol = '';
  secFilings: StockInfo[];
  news: StockInfo[];
  social: StockTwit[];
  stocktwitsData = {
    bullish: 0,
    bearish: 0,
    neutral: 0,
    watchlist_count: 0,
    title: ''
  };
  secLoading = false;
  newsLoading = false;
  socialLoading = false;
  nextEarnings = '';
  fundamentalLoading = false;
  fundamentals!: {
    finviz: FinvizFundamental | null,
    yahoo: YahooFundamental | null
  };
  otherFundamentals = {
    nextEarnings: '',
    operatingExpenses: '',
    operatingExpensesDate: ''
  };
  related = [];

  form = this.fb.group({
    symbol: ['', Validators.required],
    filing_type: [null]
  });
  errorMessage = null;
  constructor(private fb: FormBuilder, private stockInfoService: StockInfoService, private router: Router) {
    this.secFilings = [];
    this.news = [];
    this.social = [];
    this.fundamentals = {
      yahoo: null,
      finviz: null
    };

  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    console.log('fetching data');
    this.fundamentalLoading = true;
    this.symbol = this.form.value.symbol;

    this.stockInfoService.getFundamentals(this.form.value).subscribe( res => {
      console.debug('fundamentals', res);
      this.fundamentalLoading = false;
      if (res.response.finviz.status === 'fulfilled') {
        this.fundamentals.finviz = res.response.finviz;
      }
      else {
        console.error('finviz failed');
      }
      if (res.response.yahoo.status === 'fulfilled') {
        this.fundamentals.yahoo = res.response.yahoo;
        if (res.response.yahoo.value && res.response.yahoo.value.earnings && res.response.yahoo.value.earnings.earningsChart) {
          if (res.response.yahoo.value.earnings.earningsChart.earningsDate) {
            this.otherFundamentals.nextEarnings = res.response.yahoo.value.earnings.earningsChart.earningsDate[0].fmt;
          } else {
            this.otherFundamentals.nextEarnings = '';
          }
        } else {
          this.otherFundamentals.nextEarnings = '';
        }
        // tslint:disable-next-line:max-line-length
        if (res.response.yahoo.value && res.response.yahoo.value.incomeStatementHistory && res.response.yahoo.value.incomeStatementHistory.incomeStatementHistory) {
          // tslint:disable-next-line:max-line-length
          this.otherFundamentals.operatingExpenses = res.response.yahoo.value.incomeStatementHistory.incomeStatementHistory[0].totalOperatingExpenses.fmt;
          // tslint:disable-next-line:max-line-length
          this.otherFundamentals.operatingExpensesDate = res.response.yahoo.value.incomeStatementHistory.incomeStatementHistory[0].endDate.fmt;
        }
      }
      else {
        console.error('yahoo failed');
      }
      console.log('fundamentals list', this.fundamentals);
      },
      error => {
        console.error(error);
      });


    this.getSecFilings();
    this.loadNews();
    this.loadSocial();
    this.loadRealated();
  }

  public loadSocial(): void {
    this.social = [];
    this.socialLoading = true;
    this.stockInfoService.getSocial(this.form.value).subscribe(res => {
      console.debug('social', res);
      let stocktwits: StockTwit[] = [];
      let twitter: StockInfo[] = [];
      if (res.response.stockTwits.status === 'fulfilled') {
        stocktwits = res.response.stockTwits.value.messages;
        this.stocktwitsData = {
          bearish: res.response.stockTwits.value.bearish_twits,
          bullish: res.response.stockTwits.value.bullish_twits,
          neutral: res.response.stockTwits.value.neutral_twits,
          watchlist_count: res.response.stockTwits.value.watchlist_count,
          title: res.response.stockTwits.value.ticker_name
        };
      } else {
        console.error('could not get data from stocktwits');
      }
      if (res.response.twitter.status === 'fulfilled') {
        twitter = res.response.twitter.value;
      } else {
        console.error('could not get data from twitter');
      }
      this.socialLoading = false;
      console.log('twitter msg', twitter);
      console.log('stocktwits msg', stocktwits);
      this.social = this.social.concat(twitter, stocktwits);
      this.social.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      console.log('list', this.social);
    });
  }

  public loadNews(): void {
    this.news = [];
    this.newsLoading = true;
    this.stockInfoService.getNews(this.form.value).subscribe(res => {
      console.debug('news', res);
      if (res.response.seekingalpha.status === 'fulfilled') {
        this.newsLoading = false;
        this.news = res.response.seekingalpha.value;
      } else {
        this.news = [];
        console.error('Could not fetch news');
      }
    });
  }

  loadSecFilings(type= ''): void {
    if (type === '') {
      this.form.patchValue({filing_type: null});
    } else {
      this.form.patchValue({filing_type: type});
    }
    this.getSecFilings();
  }

  loadRealated(): void {
    this.related = [];
    this.stockInfoService.getRelated(this.form.value).subscribe( res => {
      if ( res.response.status === 'fulfilled') {
        this.related = res.response.value;
      }
      else {
        this.related = [];
        console.error('could not find related stocks');
      }
    });

  }

  private getSecFilings(): void {
    this.secFilings = [];
    this.secLoading = true;
    this.stockInfoService.getSecFilings(this.form.value).subscribe( res => {
      console.debug('sec', res, this.form.value);
      this.secFilings = res;
      this.secLoading = false;
    }, error => {
      this.secFilings = [];
      console.error(error);
    });
  }

  onRelatedSelected(stockElement: string): void {
    this.form.setValue({symbol: stockElement, filing_type: null});
    this.onSubmit();
  }
}
