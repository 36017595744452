import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PartialProfitAndLosses, TradeModel} from '../models/trade.model';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {ExecutionModel} from '../models/execution.model';

@Injectable({
  providedIn: 'root'
})

export class TradesService {
  cache: any = {};
  baseurl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getTrades(params: {dateStart?: Date | 0; dateEnd?: Date | 0; asc?: boolean}, withCache = true): Observable<TradeModel[]> {
    if (this.cache.trades && withCache) {
      console.log('returning cached trades', this.cache.trades);
      return this.cache.trades;
    }

    let httpParams = new HttpParams();
    if ( params.dateStart ){
      httpParams = httpParams.append('dateStart', params.dateStart.toISOString());
    }
    if ( params.dateEnd ){
      httpParams = httpParams.append('dateEnd', params.dateEnd.toISOString());
    }
    httpParams = httpParams.append('asc', params.asc === false ? 'false' : 'true');

    console.warn(httpParams);

    console.log('[GET] /trades');
    this.cache.trades = this.http.get<TradeModel[]>(this.baseurl + 'trades', {params: httpParams})
      .pipe(
        map(data => {
          data.map( trade => {
            trade.SK = trade.SK.replace('TRADE#', '');
            trade.profitable = trade.profitAndLosses >= 0;
            trade.profitAndLosses = trade.profitAndLosses / 10000;
            return trade;
          } );
          return data;
        }),
        shareReplay(1),
        catchError( err => {
          delete this.cache.trades;
          return EMPTY;
        })
      );
    return this.cache.trades;
  }

  getTrade(uuid: string, withCache = true): Observable<TradeModel> {
    if (this.cache[uuid] && withCache) {
      console.log('returning cached trades', this.cache[uuid]);
      return this.cache[uuid];
    }
    console.log(`[GET] /trade/${uuid}`);
    this.cache[uuid] = this.http.get<TradeModel>(this.baseurl + `trade/${uuid}`)
      .pipe(
        map( trade => {
          trade.SK = trade.SK.replace('TRADE#', '');
          trade.profitAndLosses = trade.profitAndLosses / 10000;
          trade.avgBuyPrice = trade.avgBuyPrice / 10000;
          trade.avgSellPrice = trade.avgSellPrice / 10000;
          trade.profitable = trade.profitAndLosses >= 0;
          return trade;
        }),
        shareReplay(1),
        catchError( err => {
          delete this.cache[uuid];
          return EMPTY;
        })
      );
    return this.cache[uuid];
  }

  getTradeWithExecutions(uuid: string, withCache = true): Observable<{trade: TradeModel, executions: ExecutionModel[]}> {
    if (this.cache[uuid] && withCache) {
      console.log('returning cached trades with executions', this.cache[uuid]);
      return this.cache[uuid];
    }
    console.log(`[GET] /trade/${uuid}/executions`);
    this.cache[uuid] = this.http.get<any[]>(this.baseurl + `trade/${uuid}/executions`)
      .pipe(
        map( (data) => {
          return this.normalizeTradeAndExecutions(data);
        }),
        shareReplay(1),
        catchError( err => {
          delete this.cache[uuid];
          return EMPTY;
        })
      );
    return this.cache[uuid];
  }

  createTrade(trade: TradeModel): Observable<TradeModel> {
    return this.http.post<TradeModel>(this.baseurl + 'trade' , trade);
  }

  updateTrade(id: string, newValues: TradeModel): Observable<TradeModel> {
    return this.http.put<TradeModel>(this.baseurl + `trade/${id}` , newValues);
  }

  createExecution(execution: ExecutionModel): Observable<ExecutionModel> {
    execution.price = execution.price * 10000;
    return this.http.post<ExecutionModel>(this.baseurl + 'execution', execution);
  }

  getExecution(uuid: string): Observable<ExecutionModel>{
    if (this.cache[uuid]) {
      console.log('returning cached execution', this.cache[uuid]);
      return this.cache[uuid].pipe(
        map( (execution: ExecutionModel) => {
          return execution;
        })
      );
    }
    this.cache[uuid] = this.http.get<ExecutionModel>(this.baseurl + `execution/${uuid}`)
      .pipe(
        map( execution => {
            execution.SK = execution.SK.replace('EXECUTION#', '');
            execution.price = execution.price / 10000;
            return execution;
          }),
        shareReplay(1),
        catchError( err => {
          delete this.cache[uuid];
          return EMPTY;
        })
      );
    return this.cache[uuid];
  }

  updateExecution(uuid: string, newValues: ExecutionModel): Observable<ExecutionModel> {
    newValues.price = newValues.price * 10000;
    delete newValues.date;
    delete newValues.time;
    this.cache[uuid] = this.http.put<ExecutionModel>(this.baseurl + `execution/${uuid}` , newValues)
      .pipe( map( execution => {
        execution.SK = execution.SK.replace('EXECUTION#', '');
        execution.price = execution.price / 10000;
        return execution;
      }));
    return this.cache[uuid];
  }

  saveChartData(uuid: string): Observable<TradeModel> {
    return this.http.post<TradeModel>(this.baseurl + `trade/${uuid}/chart`, {})
      .pipe(
        map( (res: any) => {
          console.warn(res);
          if (res.chartData) {
            // @ts-ignore
            res.chartData.sort ( (a, b) => {
              return (a.time < b.time ) ? -1 : ((a.time > b.time) ? 1 : 0);
            } );
          }
          console.log('updated res');
          console.warn(res);
          return res;
        })
      );
  }

  public clearCache(): void {
    this.cache = {};
  }

  // @ts-ignore
  private normalizeTradeAndExecutions(data): { trade: TradeModel, executions: any[] } {
    const tradeAndExecutions = {
      trade: {} as TradeModel,
      executions: [] as ExecutionModel[]
    };
    // @ts-ignore
    data.forEach( item => {
      item.SK = item.SK.replace(`${item.entity.toUpperCase()}#`, '');
      if (item.entity === 'trade') {
        item.profitAndLosses = item.profitAndLosses / 10000;
        item.avgBuyPrice = item.avgBuyPrice / 10000;
        item.avgSellPrice = item.avgSellPrice / 10000;
        item.profitable = item.profitAndLosses >= 0;
        if (item.stats && item.stats.profitAndLosses) {
          item.stats.profitAndLosses.forEach( (partialProfit: PartialProfitAndLosses) => {
            partialProfit.profit = partialProfit.profit / 10000;
            partialProfit.SK = partialProfit.SK.replace('EXECUTION#', '');
          } );
        }
        tradeAndExecutions.trade = item;
      } else if (item.entity === 'execution') {
        item.price = item.price / 10000;
        tradeAndExecutions.executions.push(item);
      }
    });
    tradeAndExecutions.executions.sort( (a, b) => {
      return (a.GSI3SK < b.GSI3SK) ? -1 : ((a.GSI3SK > b.GSI3SK) ? 1 : 0);
    });
    if (tradeAndExecutions.trade.chartData) {
      tradeAndExecutions.trade.chartData.sort ( (a, b) => {
        return (a.time < b.time ) ? -1 : ((a.time > b.time) ? 1 : 0);
      } );
    }
    return tradeAndExecutions;
  }

  deleteTrade(uuid: string): Observable<any> {
    return this.http.delete(this.baseurl + `trade/${uuid}`);
  }

  deleteExecution(uuid: string): Observable<any> {
    return this.http.delete(this.baseurl + `execution/${uuid}`);
  }

  getSignedUrlForAttachment(id: string): Observable<any> {
    return this.http.post<TradeModel>(this.baseurl + `trade/${id}/upload` , {});
  }

  uploadAttachmentToSignedUrl(id: string, url: string, formData: any): Observable<any> {
    return this.http.put(url , formData, { responseType: 'blob'});
  }
}

