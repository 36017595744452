<div class="container mt-5">
  <div class="row">
    <div class="col-sm-12">

      <!-- DATE SELECTION -->
      <form class="form-inline">
        <div class="form-group hidden" >
          <div class="input-group">
            <input name="datepicker"
                   class="form-control"
                   ngbDatepicker
                   #datepicker="ngbDatepicker"
                   [autoClose]="'outside'"
                   (dateSelect)="onDateSelection($event)"
                   [displayMonths]="2"
                   [dayTemplate]="t"
                   outsideDays="hidden"
                   [startDate]="fromDate!"
                   tabindex="-1">
            <ng-template #t let-date let-focused="focused">
        <span class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
            </ng-template>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <input #dpFromDate
                   class="form-control" placeholder="yyyy-mm-dd"
                   name="dpFromDate"
                   [value]="formatter.format(fromDate)"
                   (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
            <div class="input-group-append">
              <button class="btn datepicker-outline" (click)="datepicker.toggle()" type="button">
                  <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group ml-2">
          <div class="input-group">
            <input #dpToDate
                   class="form-control" placeholder="yyyy-mm-dd"
                   name="dpToDate"
                   [value]="formatter.format(toDate)"
                   (input)="toDate = validateInput(toDate, dpToDate.value)">
            <div class="input-group-append">
              <button class="btn datepicker-outline" (click)="datepicker.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </form>
{{fromDate | json}}
    </div>
  </div>
</div>
