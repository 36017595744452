import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {ExecutionModel} from '../models/execution.model';
import {StockInfo, SocialResponse, NewsResponse, FundamentalsResponse} from '../models/stock-info.model';

@Injectable({
  providedIn: 'root'
})

export class StockInfoService {
  cache: any = {};
  baseurl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getSecFilings(data: {symbol: string, filing_type: string | null }, withCache = true): Observable<StockInfo[]> {
    return this.http.post<StockInfo[]>(this.baseurl + 'stock-info/sec', data);
  }
  getNews(data: {symbol: string}, withCache = true): Observable<NewsResponse> {
    return this.http.post<NewsResponse>(this.baseurl + 'stock-info/news', data);
  }
  getSocial(data: {symbol: string}, withCache = true): Observable<SocialResponse> {
    return this.http.post<SocialResponse>(this.baseurl + 'stock-info/social', data);
  }

  getFundamentals(data: {symbol: string}, withCache = true): Observable<FundamentalsResponse> {
    return this.http.post<FundamentalsResponse>(this.baseurl + 'stock-info/fundamentals', data);
  }

  getRelated(data: {symbol: string}, withCache = true): Observable<any> {
    return this.http.post<any>(this.baseurl + 'stock-info/related', data);
  }
}

