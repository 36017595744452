<div class="container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="mt-5">
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="symbol" class="form-label">Symbol</label>
        <input readonly formControlName="symbol" type="text" class="form-control" id="symbol">
      </div>
      <div class="col-md-4 mb-3">
        <label for="amount" class="form-label">Amount</label>
        <input formControlName="amount" type="number" class="form-control" id="amount">
      </div>
      <div class="col-md-4 mb-3">
        <label for="price" class="form-label">Price</label>
        <input formControlName="price" type="number" class="form-control" id="price">
      </div>
    </div>
    <div class="form-row">
      <div class="mb-3 col-md-4">
        <div class="form-group">
          <label for="date" class="form-label">Date</label>
          <div class="input-group" style="align-self: center">
            <input class="form-control" placeholder="yyyy-mm-dd"
                   name="dp" formControlName="date" ngbDatepicker #d="ngbDatepicker" id="date">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 col-md-8">
        <label for="time" class="form-label">Time</label>
        <ngb-timepicker formControlName="time" [seconds]="true" id="time" [spinners]="false"></ngb-timepicker>
      </div>
    </div>
    <div class="mb-3">
      <label for="timestamp" class="form-label">Timestamp</label>
      <input formControlName="timestamp" type="text" class="form-control" id="timestamp" readonly>
    </div>
    <div class="mb-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" formControlName="side" id="buy" value="B">
        <label class="form-check-label" for="buy">Buy/Cover</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" formControlName="side" id="sell" value="S">
        <label class="form-check-label" for="sell">Sell/Short</label>
      </div>
    </div>
    <ngb-alert type="danger" *ngIf="errorMessage">Could not save the trade: {{errorMessage}}</ngb-alert>
    <button type="submit" class="btn btn-primary" [disabled]="form.invalid || isLoading">Submit</button>
  </form>

</div>
