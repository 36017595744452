import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DashBoardComponent} from './dash-board/dash-board.component';
import {TradesComponent} from './trades/trades.component';
import {ExecutionsComponent} from './executions/executions.component';
import {TradeComponent} from './trade/trade.component';
import {CreateTradeComponent} from './create-trade/create-trade.component';
import {EditTradeComponent} from './edit-trade/edit-trade.component';
import {CreateExecutionComponent} from './create-execution/create-execution.component';
import {EditExecutionComponent} from './edit-execution/edit-execution.component';
import {StockInfoComponent} from './stock-info/stock-info.component';

const routes: Routes = [
  { path: '',                 redirectTo: '/stock-info', pathMatch: 'full' },
  { path: 'home',             component: DashBoardComponent, canActivate: [] },
  { path: 'trades',             component: TradesComponent, canActivate: [] },
  { path: 'trade/create',             component: CreateTradeComponent, canActivate: [] },
  { path: 'trade/:uuid',             component: TradeComponent, canActivate: [] },
  { path: 'trade/:uuid/edit',             component: EditTradeComponent, canActivate: [] },
  { path: 'trade/:uuid/execution/create/:tradesymbol', component: CreateExecutionComponent, canActivate: [] },
  { path: 'executions',             component: ExecutionsComponent, canActivate: [] },
  { path: 'execution/:uuid/edit',             component: EditExecutionComponent, canActivate: [] },
  { path: 'stock-info',             component: StockInfoComponent, canActivate: [] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
