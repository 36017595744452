
<div class="list-group">
 <div class="list-group-item list-group-item-action" *ngFor="let item of items" (click)="openModal(item, content)"> <!-- active-->
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">{{item.title}}</h5>
      <small>{{item.date | onceUponAPipe}}</small>
    </div>
    <p class="mb-1">{{item.description}}</p>
   <fa-icon [ngClass]="{'app-red-text': item.sentiment === 'Bearish', 'app-green-text': item.sentiment ==='Bullish'}" *ngIf="item.source === 'StockTwits'" [icon]="faStockTwits"></fa-icon>
   <fa-icon *ngIf="item.source === 'Twitter'" [icon]="faTwitter"></fa-icon>
   <small *ngIf="!['Twitter','StockTwits'].includes(item.source)">{{item.source}}</small>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{modalTitle}}</h4>
    <button type="button" class="close dark-light-text" aria-label="Close"  (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body">
    <p class="dark-light-text">{{modalDate | date}}</p>
    <p>{{modalBody}}</p>
  </div>
  <div class="modal-footer">
    <a type="button" class="btn btn-primary" [href]="modalLink" target="_blank">Open</a>
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
