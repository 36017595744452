import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TradeModel} from '../../models/trade.model';
import {TradesService} from '../../services/trades.service';
import {ExecutionModel} from '../../models/execution.model';
import {createChart, CrosshairMode} from 'lightweight-charts';

@Component({
  selector: 'app-trade',
  templateUrl: './trade.component.html',
  styleUrls: ['./trade.component.scss']
})
export class TradeComponent implements OnInit {
  trade: Partial<TradeModel> = {};
  executions: Partial<ExecutionModel[]> = [];
  chartLoading = false;
  id: string;
  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tradeService: TradesService
    ) {
    this.id = '';
  }

  ngOnInit(): void {
    this.route.params.subscribe( (params: Params) =>  {
      if (!params.uuid) {
        this.router.navigate(['/']);
      }
      this.id = params.uuid;
      this.tradeService.getTradeWithExecutions(params.uuid, false)
        .subscribe( data => {
          this.trade = data.trade;
          if ( data.trade.stats && data.trade.stats.profitAndLosses ) {
            data.trade.stats.profitAndLosses.map(item => {
              const idx = data.executions.findIndex(o => o.SK === item.SK);
              data.executions[idx].partialProfit = item.profit;
              data.executions[idx].partialSide = item.side;
              data.executions[idx].partialIsProfitable = item.isProfitable;
            });
          }
          this.executions = data.executions;
          this.isLoading = false;
        });
    });
  }

  generateChart(): void {
    this.chartLoading = true;
    this.tradeService.saveChartData(this.id).subscribe( data => {
      this.trade.chartData = data.chartData;
      this.chartLoading = false;
    }, error => {
      console.error(error);
      this.chartLoading = false;
    });
  }
}
