import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-related-stocks',
  templateUrl: './related-stocks.component.html',
  styleUrls: ['./related-stocks.component.scss']
})
export class RelatedStocksComponent implements OnInit {
  @Input() related = [];

  constructor() { }

  ngOnInit(): void {
  }

}
