<div class="container">
  <div class="row mb-5 mt-5">
    <button [routerLink]="['/trade','create']" type="button" class="btn btn-primary btn-lg">New trade</button>
  </div>

  <div class="row">
    <form class="form-inline">
      <div class="form-group hidden" >
        <div class="input-group">
          <input name="datepicker"
                 class="form-control"
                 ngbDatepicker
                 #datepicker="ngbDatepicker"
                 [autoClose]="'outside'"
                 (dateSelect)="onDateSelection($event)"
                 [displayMonths]="2"
                 [dayTemplate]="t"
                 outsideDays="hidden"
                 [startDate]="fromDate!"
                 tabindex="-1">
          <ng-template #t let-date let-focused="focused">
        <span class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
          </ng-template>
        </div>
      </div>
      <div class="form-group">
        <div class="input-group">
          <input #dpFromDate
                 class="form-control" placeholder="yyyy-mm-dd"
                 name="dpFromDate"
                 [value]="formatter.format(fromDate)"
                 (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
          <div class="input-group-append">
            <button class="btn datepicker-outline" (click)="datepicker.toggle()" type="button">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group ml-2">
        <div class="input-group">
          <input #dpToDate
                 class="form-control" placeholder="yyyy-mm-dd"
                 name="dpToDate"
                 [value]="formatter.format(toDate)"
                 (input)="toDate = validateInput(toDate, dpToDate.value)">
          <div class="input-group-append">
            <button class="btn datepicker-outline" (click)="datepicker.toggle()" type="button">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="btn btn-primary" (click)="getTrades(false)">Apply</div>
    </form>
  </div>

  <div class="row">
    <table class="table app-table">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Symbol</th>
        <th scope="col">Date</th>
        <th scope="col">P&L</th>
        <th scope="col">User</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let trade of trades; index as i">
        <th scope="row"><a [routerLink]="['/trade',trade.SK]">{{trade.SK}}</a></th>
        <td>{{trade.symbol}}</td>
        <td>
          <ng-container *ngIf="trade.GSI3SK; else showStatus">
            {{trade.GSI3SK | date: 'short'}}
          </ng-container>
          <ng-template #showStatus>
            {{trade.status}}
          </ng-template>
        </td>
        <td [class.app-green-text]="trade.profitable" [class.app-red-text]="!trade.profitable">{{trade.profitAndLosses | number: '1.2-4'}}</td>
        <td>{{trade.user}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
