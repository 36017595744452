import {Injectable} from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/internal-compatibility';
import {EMPTY, Subject} from 'rxjs';
import { catchError, tap, switchAll } from 'rxjs/operators';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WsService {

  constructor() {
    this.socket$ = WsService.getNewWebSocket();
  }
  private socket$: WebSocketSubject<any>;
  private messagesSubject$ = new Subject();
  // @ts-ignore
  public messages$ = this.messagesSubject$.pipe(switchAll(), catchError(e => { throw e }));

  static getNewWebSocket(): WebSocketSubject<any> {
    console.log('connecting to websocket');
    return webSocket(environment.wsUrl);
  }

  public connect(): void {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = WsService.getNewWebSocket();
      const messages = this.socket$.pipe(
        tap({
          error: error => console.log(error),
        }), catchError(_ => EMPTY));
      this.messagesSubject$.next(messages);
    }
  }
  sendMessage(msg: any): void {
    this.socket$.next(msg);
  }
  close(): void {
    this.socket$.complete();
  }
}
