import {Component, ElementRef, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TradesService} from '../../services/trades.service';
import {TradeModel} from '../../models/trade.model';
import {FormBuilder, Validators} from '@angular/forms';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-trade',
  templateUrl: './edit-trade.component.html',
  styleUrls: ['./edit-trade.component.scss']
})
export class EditTradeComponent implements OnInit {
  trade: Partial<TradeModel> = {};
  id = '';
  form = this.fb.group({
    symbol: ['', Validators.required],
    notes: [null],
    status: [null]
  });
  errorMessage = null;
  closeResult = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tradeService: TradesService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe( (params: Params) =>  {
      if (!params.uuid) {
        this.router.navigate(['/']);
      }
      this.id = params.uuid;
      this.tradeService.getTrade(params.uuid, false)
        .subscribe( data => {
          this.trade = data;
          this.form.patchValue( data );
        });
    });
  }

  onSubmit(): void {
    this.tradeService.updateTrade(this.id, this.form.value).subscribe( data => {
      this.router.navigate(['/trade', this.id]);
    },
      error => {
        console.error(error.error.message);
        this.errorMessage = error.error.message;
      });
  }

  open(content: TemplateRef<any>): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  deleteTrade(modal: any): void {
    this.tradeService.deleteTrade(this.id)
      .subscribe( data => {
        console.debug('trade deleted');
        modal.close('trade deleted');
        return this.router.navigate(['/trades']);
      },
        error => {
         console.debug('ERROR', error);
          return this.router.navigate(['/trades']);
        });
  }
}
