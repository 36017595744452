import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TradesService} from '../../services/trades.service';
import {FormBuilder, Validators} from '@angular/forms';
import {ExecutionModel} from '../../models/execution.model';
import {NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-edit-execution',
  templateUrl: './edit-execution.component.html',
  styleUrls: ['./edit-execution.component.scss']
})
export class EditExecutionComponent implements OnInit {
  execution: Partial<ExecutionModel> = {};
  faCalendar = faCalendar;
  id = '';
  form = this.fb.group({
    symbol: [null, Validators.required],
    side: ['B', Validators.required],
    price: [null, Validators.required],
    amount: [null, Validators.required],
    timestamp: [null, Validators.required],
    currency: ['USD', Validators.required],
    tradeId: [null, Validators.required],
    date: [this.calendar.getToday() , Validators.required],
    time: [{hour: 0, minute: 0, second: 0}, Validators.required]
  });
  errorMessage = null;
  isSubmitting = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tradeService: TradesService,
    private fb: FormBuilder,
    private calendar: NgbCalendar
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe( (params: Params) =>  {
      if (!params.uuid) {
        this.router.navigate(['/']);
      }
      this.id = params.uuid;
      this.tradeService.getExecution(params.uuid)
        .subscribe( data => {
          data.timestamp = data.GSI3SK;
          data.tradeId = data.GSI1PK?.split('#')[3];
          console.log(data.GSI3SK);
          const tradeDate = new Date(Date.parse(data.GSI3SK));
          console.log(tradeDate.getDate());
          data.date = {year: tradeDate.getFullYear(), month: tradeDate.getMonth() + 1 , day: tradeDate.getDate() };
          data.time = {hour: tradeDate.getHours(), minute: tradeDate.getMinutes(), second: tradeDate.getSeconds() };
          console.log(data);
          this.execution = data;
          this.form.patchValue( data );
        });
    });

    this.form.get('date')?.valueChanges.subscribe( val => {
      const time = this.form.get('time')?.value;
      const datetime = new Date(val.year, val.month - 1, val.day, time.hour, time.minute, time.second);
      this.form.patchValue({
        timestamp: new Date(datetime).toISOString()
      });
      console.log(new Date(datetime).toString());
    });

    this.form.get('time')?.valueChanges.subscribe( val => {
      const date = this.form.get('date')?.value;
      const datetime = new Date(date.year, date.month - 1, date.day, val.hour, val.minute, val.second);
      this.form.patchValue({
        timestamp: new Date(datetime).toISOString()
      });
    });
  }

  onSubmit(): void {
    this.isSubmitting = true;
    this.tradeService.updateExecution(this.id, this.form.value).subscribe( data => {
        this.router.navigate(['/trade', this.execution.tradeId]);
        this.isSubmitting = false;
      },
      error => {
        console.error(error.error.message);
        this.errorMessage = error.error.message;
        this.isSubmitting = false;
      });
  }

  deleteExecution(): void {
    this.isSubmitting = true;
    this.tradeService.deleteExecution(this.id)
      .subscribe( _ => {
        this.router.navigate(['/trade', this.execution.tradeId]);
        this.isSubmitting = false;
      });
  }
}
