<div class="container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <label for="symbol" class="form-label">Symbol</label>
      <input formControlName="symbol" type="text" class="form-control" id="symbol">
    </div>
    <div class="mb-3">
      <label for="notes" class="form-label">Notes</label>
      <textarea formControlName="notes" class="form-control" id="notes"></textarea>
    </div>
    <ngb-alert type="danger" *ngIf="errorMessage">Could not save the trade: {{errorMessage}}</ngb-alert>
    <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Submit</button>
  </form>
</div>
