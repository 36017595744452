<div class="container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <label for="symbol" class="form-label">Symbol</label>
      <input formControlName="symbol" type="text" class="form-control" id="symbol">
    </div>
    <div class="mb-3">
      <label for="notes" class="form-label">Notes</label>
      <textarea formControlName="notes" class="form-control" id="notes"></textarea>
    </div>
    <ngb-alert type="danger" *ngIf="errorMessage">Could not save the trade: {{errorMessage}}</ngb-alert>
    <div>
      <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Submit</button>
      <div class="float-right btn btn-danger d-inline" (click)="open(content)">DELETE</div>
    </div>
  </form>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Trade</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('x')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete trade #{{id}}? <b>You will lose all the data </b>associated to this Trade (eg. executions, notes, chart)</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Cancel')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="deleteTrade(modal)">DELETE TRADE</button>
  </div>
</ng-template>
