import {Component, OnInit} from '@angular/core';
import {TradesService} from '../../services/trades.service';
import {TradeModel} from '../../models/trade.model';
import {addDate, dateAmountType} from '../../utils/addDate';
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.scss']
})
export class TradesComponent implements OnInit {
  hoveredDate: NgbDate | null = null;
  faCalendar = faCalendar;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  dateStart: Date | 0;
  dateEnd: Date;

  public trades: TradeModel[] = [];
  constructor(private tradesService: TradesService, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.dateEnd = new Date();
    this.dateStart = addDate(new Date(), -1, dateAmountType.YEARS);
    // @ts-ignore
    this.fromDate = new NgbDate(this.dateStart.getFullYear(), this.dateStart.getMonth(), this.dateStart.getDay());
    this.toDate = calendar.getToday();
  }

  ngOnInit(): void {
    this.getTrades();
  }

  getTrades(cache = true): void {
    this.tradesService.getTrades({dateStart: this.dateStart, dateEnd: this.dateEnd, asc: false}, cache)
      .subscribe(data => {
        this.trades = data;
      });
  }

  onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.dateStart = new Date(this.fromDate.year, this.fromDate.month, this.fromDate.day);
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.dateEnd = new Date(this.toDate.year, this.toDate.month, this.toDate.day);
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.dateStart = new Date(this.fromDate.year, this.fromDate.month, this.fromDate.day);
    }
  }

  isHovered(date: NgbDate): boolean | null {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate): boolean | null {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate): boolean | null {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

}
