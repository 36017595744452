<!--TODO:
aggiungi filtri per sec (offerings...EFFECTIVE etc)
aggiungi ticker name sulle sezioni
aggiungi fundamentals
aggiungi grafico short e grafico finviz?
-->
<div class="container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-row align-items-center mb-3 align-middle mt-5">
      <div class="col-sm-8 my-1">
        <input formControlName="symbol" type="text" class="form-control" id="symbol" placeholder="symbol">
        <ngb-alert type="danger" *ngIf="errorMessage">Error: {{errorMessage}}</ngb-alert>
      </div>
      <div class="col-sm-4 my-1">
        <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Submit</button>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-sm-12 mb-2" *ngIf="related.length > 0">
      Related symbols: <span (click)="onRelatedSelected(stock['symbol'])" class="pointer badge badge-primary mr-1" *ngFor="let stock of related">{{stock['symbol']}}</span>
    </div>
    <div class="col-sm-12">
      <app-stockcharts *ngIf="symbol" [symbol]="symbol"></app-stockcharts>
    </div>
    <div class="col-sm-12" *ngIf="fundamentals.yahoo">
      <h4 class="mt-5">{{fundamentals.yahoo?.value?.ticker_name}}</h4>
      <p class="stock-profile dark-light-text mt-2">
        {{fundamentals.yahoo?.value?.summaryProfile?.longBusinessSummary}}
      </p>
      <p>
        Sector: {{fundamentals.yahoo?.value?.summaryProfile?.sector}}
      </p>
      <p>
        Next earnings: {{otherFundamentals.nextEarnings}}
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <h4>Stats </h4>
      <div class="empty-list-group mt-2 mb-5" *ngIf="fundamentalLoading">
        <div class="spinner-border align-middle" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <app-fundamentals-table *ngIf="!fundamentalLoading" [fundamentals]="fundamentals"  [otherFundamentals]="otherFundamentals" [symbol]="symbol"></app-fundamentals-table>
    </div>
    <div class="col-sm-6">
      <h4>Sec</h4>
      <div class="btn-group" role="group" aria-label="Basic example">
        <button (click)="loadSecFilings('')" type="button" class="btn btn-app">all</button>
        <button (click)="loadSecFilings('s-3') "type="button" class="btn btn-app">offerings</button>
        <button (click)="loadSecFilings('10-k')" type="button" class="btn btn-app">earnings</button>
      </div>
      <div class="empty-list-group mt-2 mb-5" *ngIf="secFilings.length === 0">
        <div *ngIf="secLoading" class="spinner-border align-middle" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <app-list class="mt-2 mb-5" [items]="secFilings"></app-list>

    </div>
    <div class="col-sm-6">
      <h4>News <fa-icon class="pointer" (click)="loadNews()" [icon]="faRefresh"></fa-icon></h4>
      <div class="empty-list-group mt-2 mb-5" *ngIf="news.length === 0">
        <div *ngIf="newsLoading" class="spinner-border align-middle" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <app-list class="mt-2 mb-5" [items]="news"></app-list>

    </div>
    <div class="col-sm-6">
      <h4>Social <fa-icon (click)="loadSocial()" class="pointer" [icon]="faRefresh"></fa-icon></h4>
      <p>{{stocktwitsData.title}}</p>
      <small class="dark-light-text">Bearish: <span class="app-red-text">{{stocktwitsData.bearish}}</span></small>
      <small class="dark-light-text"> Bullish: <span class="app-green-text">{{stocktwitsData.bullish}}</span></small>
      <small class="dark-light-text"> Neutral: {{stocktwitsData.neutral}}</small>
      <small class="dark-light-text"> Followers: {{stocktwitsData.watchlist_count}}</small>

      <div class="empty-list-group mt-2 mb-5" *ngIf="social.length === 0">
        <div *ngIf="socialLoading" class="spinner-border align-middle" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <app-list class="mt-2 mb-5" [items]="social"></app-list>

    </div>
  </div>


</div>
