import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {StockTwit} from 'src/models/stock-info.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faBolt} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @Input() items: StockTwit[] = [];
  modalTitle = '';
  modalBody = '';
  modalLink = '';
  faTwitter = faTwitter;
  faStockTwits = faBolt;
  modalDate = new Date();

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
  }

  openModal(item: StockTwit, content: TemplateRef<any>): void {
    this.modalTitle = item.title;
    this.modalBody = item.description;
    this.modalLink = item.link;
    this.modalDate = item.date;
    this.modalService.open(content, {size: 'xl'} );
  }
}
