import {Component, Input, OnInit} from '@angular/core';
import {TradesService} from '../../services/trades.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {
  formData: FormData;

  constructor(private tradesService: TradesService) {
    this.formData = new FormData();
    /*
    this.uploader = new FileUploader({url: 'http://you_bucket.s3.amazonaws.com', disableMultipart: true});
    this.uploader.onAfterAddingFile = ((item: FileItem) => {
      this.uploadWarning = '';
      console.debug(item);
      // Check type
      let type = item.file.type;
      if (!type) {
        // type = mime.contentType(item.file.name);
        if (!type) {
          type = 'application/octet-stream';
        }
      }
      if (this.allowedContentTypes.indexOf(type) === -1) {
        console.error('MIME type not allowed:', type);
        this.uploadWarning = 'Non è permesso allegare questo tipo di documento.';
        setTimeout(() => {
          this.uploader.clearQueue();
        });
        return;
      }
      // Check size
      if (item.file.size > this.maxAllowedFileSize) {
        this.uploadWarning = 'La dimensione massima consentita per un documento è di ' + this.maxAllowedFileSize;
        setTimeout(() => {
          this.uploader.clearQueue();
        });
        return;
      }
    });
    this.uploader.onCompleteItem = ((item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.uploader.clearQueue();
      if (status !== 200) {
        console.error('Upload to S3 failed:', status, response);
        console.error('Headers:', headers);
        // Show the error to the user
        if (status !== 0) { // 0 is when the user cancel
          this.uploadWarning = 'Il caricamento non è riuscito, riprovare più tardi.';
        }
      } else {
      }
    });*/
  }
  hasBaseDropZoneOver = true;
  allowedContentTypes = ['text/plain', 'image/jpeg', 'image/png', 'image/x-png', 'image/svg+xml', 'image/gif', 'application/pdf',
    'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'audio/aac', 'text/html', 'audio/mpeg', 'video/mpeg', 'audio/ogg', 'video/ogg', 'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/rtf', 'audio/wav', 'audio/m4a',
    'audio/webm', 'video/webm', 'image/webp', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/xml', 'text/xml', 'video/3gpp', 'audio/3gpp', 'video/3gpp2', 'audio/3gpp2', 'text/csv', 'video/mp4', 'video/quicktime',
    'application/vnd.google-apps.audio', 'application/vnd.google-apps.spreadsheet', 'application/epub+zip', 'application/pkcs7-mime'];
  maxAllowedFileSize: number = 256 * 1024 * 1024; // 256 MB
  uploadWarning = '';


  ngOnInit(): void {
  }

}
