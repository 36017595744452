import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {createChart, CrosshairMode} from 'lightweight-charts';
import {PriceData} from '../../models/trade.model';
import {ExecutionModel} from '../../models/execution.model';

@Component({
  selector: 'app-candlestick-chart',
  templateUrl: './candlestick-chart.component.html',
  styleUrls: ['./candlestick-chart.component.scss']
})

export class CandlestickChartComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('chart', {static: true}) chartElem: ElementRef | undefined;
  @Input()
  priceData: Partial<PriceData[]> = [];
  @Input()
  executions: any[] = [];
  chart: any;
  isDailyChart = false;
  candleSeries: any;
  volumeSeries: any;

  ngAfterViewInit(): void {
    // https://codesandbox.io/s/9inkb
    if (!this.priceData || this.priceData.length === 0) {
      console.error('no data provided to draw the chart');
      return;
    }

    this.isDailyChart = this.priceData[0]?.type === 'D';

    // @ts-ignore
    this.chart = createChart(this.chartElem?.nativeElement as HTMLElement, {
      width: this.chartElem?.nativeElement.width,
      height: 500,
      layout: {
        backgroundColor: '#0b0f1b',
        textColor: 'rgba(255, 255, 255, 0.9)',
      },
      grid: {
        vertLines: {
          color: '#131927',
        },
        horzLines: {
          color: '#131927',
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      rightPriceScale: {
        borderColor: '#334158',
      },
      timeScale: {
        borderColor: '#334158',
        timeVisible: true,
        // @ts-ignore
        /*
        tickMarkFormatter: (time, tickMarkType, locale) => {
          console.log(time, tickMarkType, locale);
          // tslint:disable-next-line:max-line-length
          return new Date(time * 1000).getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) +
          ':' +new Date(time * 1000).getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
        },
        */
      }
    });

    this.candleSeries = this.chart.addCandlestickSeries({
      upColor: '#10d9ae',
      downColor: '#db1f4e',
      borderDownColor: '#db1f4e',
      borderUpColor: '#10d9ae',
      wickDownColor: '#929fbb',
      wickUpColor: '#929fbb',
      lastValueVisible: false,
      priceLineVisible: false
    });

    this.volumeSeries = this.chart.addHistogramSeries({
      color: '#182233',
      lineWidth: 2,
      priceFormat: {
        type: 'volume',
      },
      overlay: true,
      scaleMargins: {
        top: 0.9,
        bottom: 0,
      },
    });
    this.setData();
  }

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
        this.setData();
    }

  ngOnInit(): void {
  }

  private setData(): void {
    const volumeData = this.priceData.map(i => {
      return {
        // @ts-ignore
        time: i.time,
        // @ts-ignore
        value: i.volume
      };
    });
    this.candleSeries.setData(this.priceData);
    this.volumeSeries.setData(volumeData);

    if (!this.executions || this.executions.length === 0) {
      return;
    }
    const markers = this.executions.map(i => {
      const markerDate = new Date(i.GSI3SK);
      if (this.isDailyChart) {
        markerDate.setHours(12, 0, 0, 0);
      }
      return {
        time: Math.floor(+markerDate / 1000),
        position: i.side === 'B' ? 'belowBar' : 'aboveBar',
        shape: i.side === 'B' ? 'arrowUp' : 'arrowDown',
        text: i.side === 'B' ? 'Buy ' + i.amount + '@' + i.price : 'Sell ' + i.amount + '@' + i.price,
        color: i.side === 'B' ? '#10d9ae' : '#db1f4e'
      };
    });
    this.candleSeries.setMarkers(markers);

    this.chart.timeScale().fitContent();
  }

}
