import { Component, OnInit } from '@angular/core';
import {TradesService} from '../../services/trades.service';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-create-execution',
  templateUrl: './create-execution.component.html',
  styleUrls: ['./create-execution.component.scss']
})
export class CreateExecutionComponent implements OnInit {
  form = this.fb.group({
    symbol: [null, Validators.required],
    side: ['B', Validators.required],
    price: [null, Validators.required],
    amount: [null, Validators.required],
    timestamp: [null, Validators.required],
    currency: ['USD', Validators.required],
    tradeId: [null, Validators.required],
    date: [this.calendar.getToday() , Validators.required],
    time: [{hour: 15, minute: 30, second: 0}, Validators.required]
  });
  errorMessage = null;
  tradeId: string;
  tradeSymbol: string;
  isLoading = false;
  faCalendar = faCalendar;


  constructor(
    private fb: FormBuilder, private tradeService: TradesService,
    private router: Router, private route: ActivatedRoute,
    private calendar: NgbCalendar)
    {
      this.tradeId = '';
      this.tradeSymbol = '';
    }

  ngOnInit(): void {
    this.route.params.subscribe( (params: Params) =>  {
      if (!params.uuid) {
        this.router.navigate(['/']);
      }
      this.tradeId = params.uuid;
      this.tradeSymbol = params.tradesymbol;
      const now = new Date();
      this.form.patchValue({
        symbol: this.tradeSymbol,
        tradeId: this.tradeId,
        timestamp: now.toISOString()
      });
    });

    this.form.get('date')?.valueChanges.subscribe( val => {
      const time = this.form.get('time')?.value;
      const datetime = new Date(val.year, val.month -1, val.day, time.hour, time.minute, time.second);
      this.form.patchValue({
        timestamp: new Date(datetime).toISOString()
      });
      console.log(new Date(datetime).toString());
    });

    this.form.get('time')?.valueChanges.subscribe( val => {
      const date = this.form.get('date')?.value;
      const datetime = new Date(date.year, date.month -1, date.day, val.hour, val.minute, val.second);
      this.form.patchValue({
        timestamp: new Date(datetime).toISOString()
      });
    });
  }

  onSubmit(): void {
    this.isLoading = true;
    console.log(this.form.value);
    this.tradeService.createExecution(this.form.value).subscribe( data => {
      console.log(data);
      this.tradeService.clearCache();
      this.router.navigate(['/trade', this.tradeId]);
      this.isLoading = false;
    }, error => {
      console.error(error.error.message);
      this.errorMessage = error.error.message;
      this.isLoading = false;
    });
  }
}
